<template>
  <div class="ewdas">
  <my-card-list :data="comlist" :columns="4" :gutter="22">
    <el-card slot-scope="{item,index}" shadow="never" :key="index">
      <div class="camera-list-box"  @dblclick="gotocameradetail(item.cameraId)">
        <div class="image">
          <el-image class="image" :src="item.liveImage"></el-image>
        </div>
        <div class="showname">
          <div v-if="getcid===0">{{item.pondName}}</div>
          <div v-else>{{getcnamessss(item.cameraName)}}</div>
        </div>
      </div>
    </el-card>
  </my-card-list>
    <live-video v-if="flvurl" :cameraId="cameraId" @changeimg="changeliveImage" v-model="flvurl"></live-video>
  </div>
</template>

<script>
import MyCardList from '@xdh/my/ui/lib/components/my-card-list'
import LiveVideo from "@/views/component/liveVideo";
import {cameraInfo, etHkCameraListByPondId, getHkCameraAddress} from "@/utils/api";
import {mapState} from "vuex";
export default {
  components:{LiveVideo, MyCardList},
  data() {
    return {
      flvurl:'',
      cameraId:0,
      list:[],
    }
  },
  computed:{
    comlist(){
      if (this.getcid>0){
        return this.list
      }
      let list = [];
      this.list.map(i=>{
        if (!this.choosecameraarea&&i.areaId>0){
          i.cameraInfoXinBei.map(ii=>{
            list.push(ii);
          })
        }
        if (this.choosecameraarea===i.areaName){
          if (this.choosecamerafarm){
            if (this.choosecamerafarm===i.farmName){
              i.cameraInfoXinBei.map(ii=>{
                list.push(ii);
              })
            }
          }else{
            i.cameraInfoXinBei.map(ii=>{
              list.push(ii);
            })
          }

        }
      })
      return list;
    },
    getcid(){
      return this.$route.query&&this.$route.query.cid?this.$route.query.cid:0
    },
    getcname(){
      return this.$route.query&&this.$route.query.cname?this.$route.query.cname:0
    },
    ...mapState({
      choosecameraarea: state =>state.choosecameraarea,
      choosecamerafarm: state =>state.choosecamerafarm
    }),
  },

  mounted() {
      this.cameraInfo()
  },
  methods:{
    getcnamessss(name){
      return this.getcname+name
    },
    cameraInfo(){
      if(this.getcid>0){
        etHkCameraListByPondId(this.getcid).then(res=>{
          if (res.data&&res.data.length>0){
            this.list=res.data
          }else{
            this.list=[]
          }

        })

      }else{
        cameraInfo().then(res=>{
          this.list=res.data
          let arealist=[]
          res.data?.map(i=> {
            arealist.push({areaId: i.areaId, farmId: i.farmId, farmName: i.farmName, areaName: i.areaName})
          })
          console.log(arealist)
          this.$store.commit('setCameraArea',arealist)
        })
      }
      console.log(this.list)

    },
    changeliveImage(id,image){
      this.list.map((i,k)=>{
        if (i.cameraId===id){
          this.$set(this.list,k, Object.assign(this.list[k],{liveImage:image}))
        }
      })
    },
    gotocameradetail(id){
      getHkCameraAddress(id).then(res=>{
        if (res.code===500){
          // this.$myMsg.notify({ content: res.message, type: 'error', time: 2000 })
          this.$message({message: res.message})
        }
        if (res.code===200){
          this.flvurl=res.data
          this.cameraId=id
        }
      })
    },
  }
};
</script>

<style scoped lang="scss">
.ewdas{
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x:hidden;
}
.el-card{
  background-color: inherit;
  border: 0;
}
.camera-list-box{
  width: 392px;
  height: 248px;
  position: relative;
  margin-bottom: 10px;
  .image{
    width: 392px;
    height: 248px;
  }
  .showname{
    width: 399px;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    border: 0;
    background: linear-gradient(89deg, #046AC2 0%, rgba(4,114,206,0) 100%);
    background-size: 100% 100%;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >div{
      margin-left: 14px;
    }
  }


}
::v-deep .el-card__body, .el-main{
  padding: 0!important;
}
</style>
