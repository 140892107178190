<template>
  <my-dv-box ref="videop" id="videop" left="0" top="0" width="100%" height="100%"  @dblclick="closevideo">
      <div :id="videokey"  style="width: 100%;height: 100%"></div>
  </my-dv-box>

</template>
<script>
import EZUIKit from "ezuikit-js";
import fullscreen from 'vue-fullscreen'
import {getHkCameraToken, upload} from "@/utils/api";
export default {
  name: "liveVideo",
  props:{
    value:'',
    // player:null,
    cameraId:0,
  },
  data(){
    return {
      accessToken:'',
      videokey:this.guid(),
      player:null,
      isupdateimg:false,
      loading:null,
      timer:null,
    }
  },
  mounted() {
    document.addEventListener('keydown', this.onEsc)
    document.getElementById("app-index").appendChild(this.$el)
    // this.loading=this.$loading({text:'请稍等'})
    this.$nextTick(_=>{
      getHkCameraToken().then(res=>{
        this.accessToken=res;
        this.playvideo()
      })
    })
  },
  beforeDestroy() {
    try {
      document.removeEventListener('keydown', this.onEsc)

    }catch (e){
      console.log(e)
    }
    if (this.loading){
      this.loading.close()
    }
    if (this.player){
      this.player=null;
    }
  },
  methods:{
    onEsc (e) {
      if (e.keyCode === 27) {
        this.closevideo()
      }
    },
    fullscreen() {
      document.getElementById(this.videokey).requestFullscreen().then(_=>{
        document.getElementById(this.videokey).addEventListener('fullscreenchange',e=>{
          document.getElementById(this.videokey).removeEventListener('fullscreenchange',e=>{})
          this.closevideo()
        })
      })

      // this.player.fullScreen()
      // this.$fullscreen.request( document.getElementById(this.videokey), {wrap: false})
    },
    cancelFullScreen() {
      if (this.player){
        this.player.cancelFullScreen();
      }
    },
    viplay(){
      setTimeout(i=>{
        // this.player.fullScreen()
      },500)
    },
    dataURLtoBlob(dataurl, name) {
      var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], name, {
        type: mime
      });
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    playvideo(){
      console.log('eeeeeeeeeeeeeeeeeee',)
     window.e= this.player  = new EZUIKit.EZUIKitPlayer({
        id: this.videokey,
        url: this.value,
        width:this.$refs.videop.$el.offsetWidth,
        height:this.$refs.videop.$el.offsetHeight,
        autoplay: true,
        audio: 0,
        handleSuccess: (res) => {
          if (this.loading){
            this.loading.close()
            this.loading=null
          }
          this.fullscreen()
          this.viplay()
        },
        handleError:data=>{
          this.$myMsg.notify({ content: data.msg, type: 'error', time: 2000 })
          console.log('错误数据',data)
          this.$emit('input','')
          this.isplayimg=false
          if (this.loading){
            this.loading.close()
            this.loading=null
          }
        },
        accessToken:this.accessToken,
        fullScreenChangeCallBack: data => {
          if (data.code){
            try {
              if (this.loading){
                this.loading.close()
                this.loading=null
              }
              if (this.player){
                this.player.play();
              }

            }catch (e){
              this.$myMsg.notify({ content: '系统异常，请稍后再试', type: 'error', time: 2000 })
              this.$emit('input','')
            }
          }else{
            this.closevideo()
            return;
            if (!this.player){
              return
            }

            if (!this.isupdateimg){
              this.isupdateimg=true
              this.player.capturePicture('default',(data)=>{
                let file=window.eee=this.dataURLtoBlob(data.base64,data.fileName)
                let formdata=new FormData()
                formdata.append('photo',file)
                formdata.append('cameraId',this.cameraId)
                this.$emit('changeimg',this.cameraId,data.base64)

                upload(formdata)
                this.isupdateimg=false
              })
            }
            setTimeout(()=>{
              // player.stop();
              this.$emit('input','')
            },1000)
          }
        },
      });
    },
    closevideo(){
      this.player.capturePicture('default',async (data) => {
        let file  = this.dataURLtoBlob(data.base64, data.fileName)
        let formdata = new FormData()
        formdata.append('photo', file)
        formdata.append('cameraId', this.cameraId)
        this.$emit('changeimg', this.cameraId, data.base64)
        try {
          this.player.stop()
          this.player.cancelFullScreen();
          upload(formdata)
          try {
            var destroyPromise = this.player.destroy();
            destroyPromise.then((data) => {
              console.log("promise 获取 数据", data)
            })
          }catch (e){
          }
          this.$emit('input','')
        } catch (e) {
          console.log(e.message)
        }

        this.isupdateimg = false
      })
    }
  }
};
</script>

<style scoped>

</style>
